import { useQuery } from "@tanstack/react-query";
import useSupabaseBrowser from "utils/supabaseBrowserClient";

import { getPagesLite } from "lib/adminApi";
import { QueryHookOptions } from "types/common";
import useUserType from "./useUserType";
import useCurrentUser from "./useCurrentUser";

// Returns pages with base information only
// Always return the react query cache data directly from hooks,
// any manipulation should be done in the getPagesLite function
// This ensure every instance of the hook does not keep a memoized version of the data
const useAllPagesLite = (options?: QueryHookOptions) => {
  const supabaseClient = useSupabaseBrowser();
  const { userType } = useUserType();
  const currentUser = useCurrentUser();

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["pages-lite", userType, currentUser?.org_id],
    queryFn: () => getPagesLite(supabaseClient, userType, currentUser?.org_id),
    refetchInterval: false,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    refetchOnMount: false,
    ...options
  });

  return {
    data: data?.data,
    isLoading,
    refetch
  };
};

export default useAllPagesLite;
