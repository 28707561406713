import { useDroppable } from "@dnd-kit/core";
import clsx from "clsx";

const AddDroppable = ({ isExpanded }: { isExpanded: boolean }) => {
  const { setNodeRef } = useDroppable({
    id: "add-droppable",
  });

  return <div ref={setNodeRef} className={clsx("fixed inset-y-0 left-0 z-50", !isExpanded && "right-[685px]")} />;
};

export default AddDroppable;
