import { useState } from "react";
import { Disclosure, Transition } from "@headlessui/react";
import clsx from "clsx";
import { ChevronRightIcon, ChevronDownIcon } from "lucide-react";

import IconButton from "components/IconButton";
import { IconButtonColor } from "components/IconButton/utils";

type FormSectionProps = {
  children: React.ReactNode;
  title?: string;
  description?: string;
  showCollapse?: boolean;
  isClose?: boolean;
  isDisabled?: boolean;
};

const FormSection = ({
  children,
  title,
  description,
  showCollapse = false,
  isClose = false,
  isDisabled
}: FormSectionProps) => {
  const [isOpen, setIsOpen] = useState(() => (showCollapse ? !isClose : true));

  return (
    <Disclosure>
      <div className="border-separator border-b py-5 last:border-b-0">
        <Disclosure.Button
          className="mb-2 flex w-full items-center justify-between text-left"
          onClick={() => showCollapse && !isDisabled && setIsOpen(!isOpen)}
        >
          {title && (
            <>
              <div className={isOpen ? "mb-2" : ""}>
                <h4 className={clsx("text-primary w-full text-lg font-semibold", isDisabled ? "text-gray-500" : "")}>
                  {title}
                </h4>
                {description && <p className="text-secondary w-full text-sm font-normal">{description}</p>}
              </div>

              {showCollapse && !isDisabled && (
                <IconButton
                  icon={isOpen ? ChevronDownIcon : ChevronRightIcon}
                  className="!rounded-full"
                  color={IconButtonColor.LIGHT}
                  tag="div"
                />
              )}
            </>
          )}
        </Disclosure.Button>
        <Transition
          show={isOpen}
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
        >
          <Disclosure.Panel>{children}</Disclosure.Panel>
        </Transition>
      </div>
    </Disclosure>
  );
};

export default FormSection;
