import { useContext } from "react";
import { NestedViewContext, NestedViewContextState } from "context/NestedViewContext";

const useNestedViewState = () => {
  const nestedState = useContext<NestedViewContextState | null>(NestedViewContext);

  if (typeof nestedState === "undefined" || typeof nestedState !== "object") {
    return {
      nestedViewStack: undefined,
      pushNestedView: () => {},
      popNestedView: () => {},
      resetNestedView: () => {},
      updateLatestNestView: () => {},
      resetNestedViewStackToItem: () => {},
      navigateToNestedView: undefined,
      updateNavigateToNestedView: () => {},
      updateNavigateCompleted: () => {},
      navigateCompleted: undefined,
      pagesInNestedView: undefined,
      updateCellSidebarPage: () => {},
      cellSidebarPage: undefined,
    };
  }

  return nestedState as NestedViewContextState;
};

export type NestedViewContextType = ReturnType<typeof useNestedViewState>;

export default useNestedViewState;
