"use client";

import React, { Fragment, useCallback, useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { Dialog, Transition } from "@headlessui/react";
import { X as CloseIcon, ExpandIcon, Minimize2 as CompressIcon } from "lucide-react";
import useKeypress from "react-use-keypress";

import AddDroppable from "components/Add/AddDroppable";
import { ESCAPE_EVENT_RESOLVED_BY_COMP, STATIC_SIDEBAR_IDS } from "utils/constants";
import useUIState from "hooks/useUIState";
import { isTargetNodeInSearchContainer } from "components/Search/utils";
import IconButton from "../IconButton";
import { IconButtonSize, IconButtonColor } from "../IconButton/utils";

type SidebarProps = {
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  className?: string;
  classNameContainer?: string;
  classNameOverlay?: string;
  classNameHeader?: string;
  showClose?: boolean;
  hideHeader?: boolean;
  title?: string;
  closeOnOutsideClick?: boolean;
  rounded?: boolean;
  leftHeader?: React.ReactNode;
  rightHeader?: React.ReactNode;
  allowDroppable?: boolean;
  showExpandButton?: boolean;
  id?: STATIC_SIDEBAR_IDS;
  visibleSidebars?: STATIC_SIDEBAR_IDS[];
  defaultExpanded?: boolean;
};

const Sidebar = ({
  children,
  isOpen,
  onClose,
  showClose = true,
  hideHeader = false,
  className,
  classNameContainer,
  classNameOverlay,
  classNameHeader,
  title = "",
  closeOnOutsideClick = true,
  rounded = false,
  leftHeader,
  rightHeader,
  allowDroppable = false,
  showExpandButton = false,
  id,
  visibleSidebars = [],
  defaultExpanded = false
}: SidebarProps) => {
  const [isExpanded, setIsExpanded] = useState(defaultExpanded);
  const { setEscapeEventResolvedByComp } = useUIState();
  const dialogRef = useRef<HTMLDivElement>(null);
  const onDialogClose = useCallback(() => {
    if (closeOnOutsideClick) {
      onClose();
    }
  }, [closeOnOutsideClick, onClose]);

  useKeypress(["Escape"], (e: any) => {
    if (isTargetNodeInSearchContainer(e.target as HTMLElement)) return;
    if (id && visibleSidebars?.length && visibleSidebars[visibleSidebars.length - 1] !== id) {
      return;
    }
    e.stopPropagation();
    e.preventDefault();
    if (isOpen) {
      setEscapeEventResolvedByComp(ESCAPE_EVENT_RESOLVED_BY_COMP.SIDEBAR);
      onClose?.();
    }
  });

  useEffect(() => {
    setIsExpanded(defaultExpanded);
  }, [defaultExpanded]);

  return (
    <div data-testid="Sidebar">
      <Transition.Root show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className={clsx("fixed inset-0 z-50 overflow-hidden", className)}
          onClose={onDialogClose}
          ref={dialogRef}
          static
        >
          <div className="absolute inset-0 overflow-hidden" id="sidebar">
            {!rounded && <Dialog.Overlay className={clsx("absolute inset-0 bg-black/75 ", classNameOverlay)} />}

            {allowDroppable && <AddDroppable isExpanded={isExpanded} />}

            <div className="fixed inset-0 flex max-w-full justify-end">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <div
                  className={clsx(
                    "w-screen",
                    rounded ? "lg:m-3 lg:rounded-2xl lg:shadow-400" : "max-w-[748px]",
                    rounded && !isExpanded && "lg:max-w-[544px]",
                    rounded && isExpanded && "lg:w-full",
                    classNameContainer
                  )}
                >
                  <div
                    className={clsx(
                      "border-separator flex h-full translate-y-0 flex-col border-l bg-white  shadow-xl transition-colors  dark:bg-gray-800",
                      rounded && "lg:rounded-2xl"
                    )}
                  >
                    {!hideHeader && (
                      <div
                        className={clsx(
                          "sticky top-0 z-20 flex w-full items-center gap-2 px-6",
                          rounded
                            ? "right-0 my-3 flex-row-reverse lg:left-0 lg:flex-row lg:px-7"
                            : "right-0 min-h-[70px] flex-row-reverse",
                          classNameHeader
                        )}
                      >
                        {showClose && (
                          <div>
                            <IconButton
                              icon={CloseIcon}
                              size={rounded ? IconButtonSize.SM : IconButtonSize.SM}
                              color={rounded ? undefined : IconButtonColor.LIGHT}
                              onClick={onClose}
                            />
                          </div>
                        )}
                        <>{rightHeader}</>
                        <div className="h-4 flex-1 text-center text-xl font-normal">{title}</div>

                        <>{leftHeader}</>
                        {showExpandButton && (
                          <IconButton
                            icon={isExpanded ? CompressIcon : ExpandIcon}
                            onClick={() => setIsExpanded((prev) => !prev)}
                          />
                        )}
                      </div>
                    )}
                    {isOpen && <div className="relative flex flex-1 flex-col overflow-hidden">{children}</div>}
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};

export default Sidebar;
