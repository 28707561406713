"use client";

import React, { createContext, useState, useCallback, useEffect } from "react";
import useAdminConfigUpdate from "hooks/useAdminConfigUpdate";
import type { Page, AdminRequest } from "types/baTypes";
import { CrudActions } from "utils/constants";
export interface AdminPageContextState {
  pageState: Page | undefined;
  updatePageStateProp: (newPropValues: Partial<Page>) => void;
  resetPageState: () => void;
  pushToPageRequestsQueue: (request: AdminRequest) => void;
}

export const AdminPageContext = createContext<AdminPageContextState | null>(null);

const { Provider } = AdminPageContext;

export const AdminPageContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [pageState, setPageState] = useState<Page | undefined>();
  const [pageRequestsQueue, setPageRequestsQueue] = useState<Array<AdminRequest>>([]);
  const [newRequestsIdMap, setNewRequestsIdMap] = useState<Map<string, string>>(new Map());
  const { handleAdminRequest } = useAdminConfigUpdate({ setNewRequestsIdMap });

  const pushToPageRequestsQueue = useCallback((request: AdminRequest) => {
    setPageRequestsQueue((prev: any) => {
      return [...prev, request];
    });
  }, []);

  const updatePageStateProp = useCallback((newPropValues: Partial<Page>) => {
    setPageState((prev: any) => {
      return {
        ...prev,
        ...newPropValues,
      };
    });
  }, []);

  const resetPageState = useCallback(() => {
    setPageState(undefined);
  }, []);

  useEffect(() => {
    if (!pageRequestsQueue.length) return;

    const requestToProcess = pageRequestsQueue[0];
    const finalInput = { ...requestToProcess.input };
    // Only for non create actions replace new id with new record id
    if (finalInput.id && `${finalInput.id}`.startsWith("new_") && requestToProcess.action !== CrudActions.CREATE) {
      finalInput.id = newRequestsIdMap.get(finalInput.id) || null;
      requestToProcess.input = finalInput;
    }

    handleAdminRequest(requestToProcess);
    const updatedRequests = pageRequestsQueue.slice(1);
    setPageRequestsQueue(updatedRequests);
  }, [pageRequestsQueue, newRequestsIdMap, handleAdminRequest]);

  return (
    <Provider
      value={{
        pageState,
        updatePageStateProp,
        resetPageState,
        pushToPageRequestsQueue,
      }}
    >
      {children}
    </Provider>
  );
};
