import { useMemo } from "react";
import { USER_TYPE } from "utils/constants";
import useCurrentUser from "./useCurrentUser";

const useUserType = () => {
  const currentUser = useCurrentUser();

  const userType = useMemo(() => {
    // If user is not staff the user type has priority
    if (!!currentUser?.type && currentUser?.type !== USER_TYPE.STAFF) {
      return currentUser?.type as USER_TYPE;
    }

    const windowOrigin = typeof window !== "undefined" ? window.origin : null;
    return windowOrigin?.includes("client")
      ? USER_TYPE.CLIENT
      : windowOrigin?.includes("pro.")
        ? USER_TYPE.PRO
        : USER_TYPE.STAFF;
  }, [currentUser]);

  return { userType };
};

export default useUserType;
