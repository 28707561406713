"use client";

import React, { createContext, useState, useCallback, useEffect } from "react";
import isEqual from "lodash/isEqual";

import { Page, TableColumnType, TableViewType } from "types/baTypes";
import { BreadcrumbItemType } from "components/Breadcrumb";
import { ViewOption } from "utils/constants";
import { RecordItem } from "types/common";

export type NestedViewState = {
  pageTable: string;
  recordId: string;
  pageData?: Page;
  viewOption: ViewOption;
  pageColumns?: TableColumnType[];
  pageFilters?: TableViewType;
  navItems: BreadcrumbItemType[] | [];
  fileColId?: string;
  showExpanded?: boolean;
  defaultPageTab?: string; // used to override default tab when opened with url
  hideSidebar?: boolean;
  isRecordLocked?: boolean; // If the record is marked as locked all items opened within the record are locked
  isParentRecordLocked?: boolean; // If the parent record is marked as locked, all current items in view are locked
  hideRecordNavigationControl?: boolean;
  hideBreadcrumb?: boolean;
  redirectOnExpand?: boolean; // When expand is clicked the url changes
  fetchPageData?: boolean; // If the page data should be fetched
  parentRecordData?: RecordItem; // Row / Item clicked to trigger nested view
  defaultPageTabPath?: string; // used to override default tab when opened with url
  pageId?: string; // used to override default tab when opened with url
  fetchRecordData?: boolean; // If the record data should be fetched
};

export type NavigateToNestedViewState = Array<{ recordId: string; pageTabId: string }>;

export type NestedViewStack = Array<NestedViewState>;

export interface NestedViewContextState {
  nestedViewStack: NestedViewStack | [];
  pushNestedView: (
    detailViewItem: NestedViewState,
    recordPage: Page | undefined,
    recordId: string,
    recordTitle?: string
  ) => void;
  popNestedView: () => NestedViewState;
  resetNestedView: () => void;
  updateLatestNestView: (detailViewItem: NestedViewState) => void;
  resetNestedViewStackToItem: (detailViewItem: NestedViewState) => void;
  navigateToNestedView?: NavigateToNestedViewState; // Used to store nested view path when navigating from url to nested view
  updateNavigateToNestedView: (updatedState?: NavigateToNestedViewState) => void;
  navigateCompleted?: boolean;
  updateNavigateCompleted: (updatedState: boolean) => void; // Tracks if the navigation has been completed
  pagesInNestedView?: string[]; // Used to track pages in nested view
  cellSidebarPage?: NestedViewState; // When a cell is clicked we display it separate from the nested view stack
  updateCellSidebarPage: (updatedState?: NestedViewState) => void;
}

export const NestedViewContext = createContext<NestedViewContextState | null>(null);

const { Provider } = NestedViewContext;

export const NestedViewContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [nestedViewStack, setNestedViewStack] = useState<NestedViewStack>([]);
  const [nestedViewStackCount, setNestedViewStackCount] = useState<number>(0);
  const [navigateToNestedView, setNavigateToNestedView] = useState<NavigateToNestedViewState>();
  const [navigateCompleted, setNavigateCompleted] = useState<boolean>(false);
  const [pagesInNestedView, setPagesInNestedView] = useState<string[]>([]);
  const [cellSidebarPage, setCellSidebarPage] = useState<NestedViewState>(); // When a cell is clicked we display it separate from the nested view stack

  const updateLatestNestView = useCallback((updateViewStateProps: NestedViewState) => {
    setNestedViewStack((prev) => {
      const latestState = prev.pop();
      return [...prev, { ...latestState, ...updateViewStateProps }];
    });
  }, []);

  const updateCellSidebarPage = useCallback((updateViewStateProps?: NestedViewState) => {
    setCellSidebarPage(() => updateViewStateProps);
  }, []);

  const pushNestedView = useCallback(
    (detailViewItem: NestedViewState, recordPage: Page | undefined, recordId: string, recordTitle?: string) => {
      const baseNavItems =
        recordPage?.title && recordPage.path && recordId && !detailViewItem?.navItems?.length
          ? [
              { title: recordPage.title, link: `/table${recordPage.path}` },
              { title: recordTitle || recordId, link: `/r${recordPage.path}/${recordId}` }
            ]
          : [];
      setNestedViewStack((prev) => {
        let prevNavItems = [...prev?.map((view) => view.navItems || []).flat()].filter(Boolean);
        if (!prevNavItems.length) {
          prevNavItems = [...baseNavItems];
        }
        return [
          ...prev,
          {
            ...detailViewItem,
            navItems: [
              ...prevNavItems,
              {
                title: detailViewItem.pageData?.title || "",
                link: `${detailViewItem.pageData?.path}`
              }
            ]
          }
        ];
      });
      setNestedViewStackCount((prev) => prev + 1);
    },
    []
  );

  const popNestedView = useCallback(() => {
    const poppedItem = nestedViewStack[nestedViewStackCount - 1];
    setNestedViewStack((prev) => prev.slice(0, prev.length - 1));
    setNestedViewStackCount((prev) => prev - 1);
    return poppedItem;
  }, [nestedViewStack, nestedViewStackCount]);

  const resetNestedView = useCallback(() => {
    setNestedViewStack([]);
    setNestedViewStackCount(0);
  }, []);

  const resetNestedViewStackToItem = useCallback(
    (detailViewItem: NestedViewState) => {
      const index = nestedViewStack.findIndex((item) => item.recordId === detailViewItem.recordId);
      if (index > -1) {
        setNestedViewStack((prev) => prev.slice(0, index + 1));
        setNestedViewStackCount(index + 1);
      }
    },
    [nestedViewStack]
  );

  const updateNavigateToNestedView = useCallback((updatedState?: Array<{ recordId: string; pageTabId: string }>) => {
    setNavigateToNestedView((prev) => [...(prev || []), ...(updatedState || [])]);
  }, []);

  const updateNavigateCompleted = useCallback((updatedState: boolean) => {
    setNavigateCompleted(updatedState);
  }, []);

  useEffect(() => {
    if (!nestedViewStack?.length) {
      if (pagesInNestedView?.length) {
        setPagesInNestedView([]);
      }
      return;
    }
    const pagesList = nestedViewStack?.map((view) => `${view.pageData?.path || ""}:${view.recordId || ""}`);
    if (!isEqual(pagesList, pagesInNestedView)) {
      setPagesInNestedView(pagesList);
    }
  }, [nestedViewStack, pagesInNestedView]);

  return (
    <Provider
      value={{
        nestedViewStack,
        pushNestedView,
        popNestedView,
        resetNestedView,
        resetNestedViewStackToItem,
        updateLatestNestView,
        navigateToNestedView,
        updateNavigateToNestedView,
        navigateCompleted,
        updateNavigateCompleted,
        pagesInNestedView,
        updateCellSidebarPage,
        cellSidebarPage
      }}
    >
      {children}
    </Provider>
  );
};
