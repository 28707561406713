import { useCallback } from "react";

import { AdminRequest } from "types/baTypes";
import { CrudActions } from "utils/constants";
import useCurrentUser from "./useCurrentUser";
import useUpdateRecord from "./useUpdateRecord";
import useUpsertDeletePageViewConfig, {
  AddViewColumnInput,
  AddViewInput,
  SaveMultipleViewColumnInput,
  SavePageViewInput,
  SaveViewColumnInput
} from "./useUpsertDeletePageViewConfig";

const useAdminConfigUpdate = ({
  setNewRequestsIdMap
}: {
  setNewRequestsIdMap: React.Dispatch<React.SetStateAction<Map<string, string>>>;
}) => {
  const {
    handleSaveView,
    handleSaveViewColumnProp,
    deleteViewColumn,
    addColumnToPageView,
    handleAddNewPageView,
    handleDeleteView,
    handleUpdateMultiViewColumnsProps
  } = useUpsertDeletePageViewConfig();
  const currentUser = useCurrentUser();
  const { updateRecordAsync } = useUpdateRecord();

  const handleUIViews = useCallback(
    async (request: AdminRequest) => {
      if (!request?.action) return;
      switch (request.action) {
        case CrudActions.CREATE:
          const newViewResp = await handleAddNewPageView({
            onSuccess: request.onSuccess,
            ...request.input
          } as AddViewInput);
          if (newViewResp?.id) {
            setNewRequestsIdMap((prev) => new Map([...prev, [request.input.id, newViewResp.id]]));
          }
          break;
        case CrudActions.UPDATE:
          await handleSaveView({
            onSuccess: request.onSuccess,
            ...request.input
          } as SavePageViewInput);
          break;
        case CrudActions.DELETE:
          await handleDeleteView({ onSuccess: request.onSuccess, ...request.input } as {
            viewId: string;
            onSuccess?: () => void;
          });
          break;
        default:
          break;
      }
      if (request.pageId) {
        updateRecordAsync({
          tableName: "pages",
          input: {
            id: request.pageId,
            updated_at: new Date().toISOString(),
            updated_by: currentUser?.user_id
          }
        });
      }
    },
    [
      handleAddNewPageView,
      handleSaveView,
      setNewRequestsIdMap,
      handleDeleteView,
      updateRecordAsync,
      currentUser?.user_id
    ]
  );

  const handleUIViewsColumns = useCallback(
    async (request: AdminRequest) => {
      if (!request?.action) return;
      switch (request.action) {
        case CrudActions.CREATE:
          const newViewResp = await addColumnToPageView({
            onSuccess: request.onSuccess,
            ...request.input
          } as AddViewColumnInput);
          if (newViewResp?.id) {
            setNewRequestsIdMap((prev) => new Map([...prev, [request.input.id, newViewResp.id]]));
          }
          break;
        case CrudActions.UPDATE:
          await handleSaveViewColumnProp({ onSuccess: request.onSuccess, ...request.input } as SaveViewColumnInput);
          break;
        case CrudActions.BULK_UPDATE:
          await handleUpdateMultiViewColumnsProps({
            onSuccess: request.onSuccess,
            ...request.input
          } as SaveMultipleViewColumnInput);
        case CrudActions.DELETE:
          await deleteViewColumn({ onSuccess: request.onSuccess, ...request.input } as {
            pageViewColumnId: string;
            onSuccess?: () => void;
          });
        default:
          break;
      }
      if (request.pageId) {
        updateRecordAsync({
          tableName: "pages",
          input: {
            id: request.pageId,
            updated_at: new Date().toISOString(),
            updated_by: currentUser?.user_id
          }
        });
      }
    },
    [
      addColumnToPageView,
      deleteViewColumn,
      setNewRequestsIdMap,
      handleSaveViewColumnProp,
      handleUpdateMultiViewColumnsProps,
      updateRecordAsync,
      currentUser?.user_id
    ]
  );

  const handleAdminRequest = useCallback(
    async (request: AdminRequest) => {
      if (!request?.tableName) return;
      switch (request.tableName) {
        case "ui_views":
          handleUIViews(request);
          break;
        case "ui_pages_views_columns":
          handleUIViewsColumns(request);
          break;

        default:
          break;
      }
    },
    [handleUIViews, handleUIViewsColumns]
  );

  return {
    handleAdminRequest
  };
};

export default useAdminConfigUpdate;
